import CryptoJS from "crypto-js";
const secretPass = "XkhZG4fW2t2W";

export const EncryptFunction = (text) => {
    const encrypted = CryptoJS.AES.encrypt(text?.toString(), secretPass).toString();
    return encodeURIComponent(encrypted);
};

export const DecryptFunction = (text) => {
    const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(text), secretPass);
    return decrypted?.toString(CryptoJS.enc.Utf8);
};

export const EncryptObjectFunction = (object) => {
    const text = JSON.stringify(object);
    const encrypted = CryptoJS.AES.encrypt(text?.toString(), secretPass).toString();
    return encodeURIComponent(encrypted);
};

export const DecryptObjectFunction = (text) => {
    const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(text), secretPass);
    return JSON.parse(decrypted?.toString(CryptoJS.enc.Utf8));
};
