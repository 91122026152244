import React from "react";
import { Button, Table } from "react-bootstrap";
// import { PiPencilSimpleBold, PiTrashBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBack } from 'react-icons/md';

export default function List() {

    const navigate = useNavigate();

    const { role } = useSelector(state => state.roleReducer);

    return (
        <div className="list-component-main-div">
            <div className="home-menu-main-div component-heading-main-div">
                <div className="list-heading-div">
                    <div className="list-title">ROLE</div>
                    <div className="list-title-line" />
                </div>
                <div className="back-btn-div-cls">
                    <Button className="back-btn-cls" onClick={() => navigate('/home')}><MdOutlineArrowBack /> BACK</Button>
                </div>
                {/* <div className="home-menu-btn add-btn-div-cls">
                    <Button className="menu-btn-cls" onClick={() => navigate('/add-role')}>ADD ROLE</Button>
                </div> */}
            </div>
            <Table responsive className="list-table-cls">
                <thead className="list-thead-cls">
                    <tr className="list-thead-tr">
                        <th>Role</th>
                        <th>Description</th>
                        <th>Permission</th>
                        {/* <th></th>
                        <th></th> */}
                    </tr>
                </thead>
                {role?.length > 0 ?
                    <tbody>
                        {role?.map((val, i) =>
                            <tr key={i} className="list-tbody-tr-cls">
                                <td>{val?.roleName}</td>
                                <td>{val?.description}</td>
                                <td>{(val?.view && val?.edit) ? "View, Edit" : val?.view ? "View" : "Edit"}</td>
                                {/* <td className="list-table-edit">
                                    <PiPencilSimpleBold /> <span>Edit</span>
                                </td>
                                <td className="list-table-delete">
                                    <PiTrashBold /> <span>Delete</span>
                                </td> */}
                            </tr>
                        )}
                    </tbody> :
                    <tbody>
                        <td colSpan={5} className="text-center pt-2 fw-600">
                            No Records Found
                        </td>
                    </tbody>
                }
            </Table>
        </div>
    )
}