import React from "react";
import Header from "../layouts/header";
import AddRoleIndex from "../components/addRole";

export default function AddRole() {

    return (
        <div>
            <Header />
            <AddRoleIndex />
        </div>
    )
};