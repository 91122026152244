import { put, all, call, takeEvery, delay } from "redux-saga/effects";
import usersActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";

const usersSaga = function* () {
    yield all([
        yield takeEvery(usersActions.GET_USER_LIST, getUserList),
        yield takeEvery(usersActions.ADD_USER, addUser),
        yield takeEvery(usersActions.UPDATE_USER, updateUser),
        yield takeEvery(usersActions.DELETE_USER, deleteUser),
    ])
};

const getUserList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: usersActions.SET_USER_LIST, payload: result?.data?.result });
        };
    } catch (err) {
        console.log(err);
    };
};

const addUser = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({ type: usersActions.GET_USER_LIST });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate('/users');
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const updateUser = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/user/${payload?.id}`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate('/users');
            yield put({ type: usersActions.GET_USER_LIST });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const deleteUser = function* (data) {
    const { payload } = data;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/user/${payload?.id}`)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({ type: commonActions.SHOW_DELETE_CONFIRMATION_MODAL, payload: { show: false, data: null } });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            yield put({ type: usersActions.GET_USER_LIST });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    };
};

export default usersSaga;