import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { PiPencilSimpleBold, PiEyeBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../common/pagination";
import { DecryptFunction, DecryptObjectFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { useNavigate } from "react-router-dom";
import candidateActions from "../../redux/candidates/actions";
import moment from "moment-timezone";
import commonActions from "../../redux/common/actions";

export default function List() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { filteredCandidatesList } = useSelector(state => state.candidateReducer);

    const { paginationCurrentPage } = useSelector(state => state.commonReducer);

    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (paginationCurrentPage >= 0) {
            setCurrentPage(paginationCurrentPage);
            const newOffset = (paginationCurrentPage * itemsPerPage) % filteredCandidatesList?.length;
            setItemOffset(newOffset);
        }
    }, [paginationCurrentPage, itemsPerPage, filteredCandidatesList?.length]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredCandidatesList?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredCandidatesList?.length / itemsPerPage);

    const handlePageChange = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredCandidatesList?.length;
        setItemOffset(newOffset);
        dispatch({ type: commonActions.SET_PAGINATION_CURRENT_PAGE, payload: event?.selected });
        setCurrentPage(event?.selected);
        window.scrollTo({ top: 0 })
    };

    const handleViewCandidate = (id) => {
        let userid = DecryptFunction(sessionStorage.getItem('auth_user'));
        if (userid) {
            dispatch({
                type: candidateActions.UPDATE_CANDIDATE_VIEW_LOG, payload: {
                    userId: userid,
                    candidateId: id
                }
            });
        };
        navigate(`/view-candidate?id=${EncryptFunction(id)}`)
    };

    return (
        <div className="list-component-main-div">
            <div className="list-heading-div">
                <div className="list-title">CANDIDATES</div>
                <div className="list-title-line" />
            </div>
            <Table responsive className="list-table-cls">
                <thead className="list-thead-cls">
                    <tr className="list-thead-tr">
                        <th>Name</th>
                        <th>E-mail</th>
                        <th>Mobile No</th>
                        <th>DOB</th>
                        {DecryptObjectFunction(sessionStorage.getItem('user_role'))?.view &&
                            <th>View</th>
                        }
                        {DecryptObjectFunction(sessionStorage.getItem('user_role'))?.edit &&
                            <th>Edit</th>
                        }
                    </tr>
                </thead>
                {filteredCandidatesList?.length > 0 ?
                
                    <tbody>
                        
                        {currentItems?.map((val, i) =>
                            <tr key={i} className="list-tbody-tr-cls">
                                <td>{val?.candidateName || 'N/A'}</td>
                                <td>{val?.emailId || 'N/A'}</td>
                                <td>{val?.mobileNo || 'N/A'}</td>
                                <td>{val.dob ? moment(val?.dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'N/A'}</td>
                                {DecryptObjectFunction(sessionStorage.getItem('user_role'))?.view &&
                                    <td className="list-table-edit" onClick={() => handleViewCandidate(val?.userId)}>
                                        <PiEyeBold /> <span>View</span>
                                    </td>
                                }
                                {DecryptObjectFunction(sessionStorage.getItem('user_role'))?.edit &&
                                    <td className="list-table-edit" onClick={() => navigate(`/edit-candidate?id=${EncryptFunction(val?.userId)}`, { state: val })}>
                                        <PiPencilSimpleBold /> <span>Edit</span>
                                    </td>
                                }
                            </tr>
                        )}
                    </tbody> :
                    <tbody>
                        <td colSpan={(DecryptObjectFunction(sessionStorage.getItem('user_role'))?.view && DecryptObjectFunction(sessionStorage.getItem('user_role'))?.edit) ? 6 : 5} className="text-center pt-2 fw-600">
                            No Records Found
                        </td>
                    </tbody>
                }
            </Table>
            {pageCount > 1 &&
                <div className="mt-4">
                    <Pagination
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                    />
                </div>
            }
        </div>
    )
}