import roleActions from "./actions";

const initialState = {
    role: [],
};

const roleReducer = (state = initialState, action) => {

    switch (action.type) {
        case roleActions.SET_ROLE_LIST:
            return {
                ...state,
                role: action.payload
            }
        default:
            return state;
    }
};

export default roleReducer;