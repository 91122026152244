import authActions from "./actions";
import commonActions from "../common/actions";
import { takeEvery, call, all, put, delay } from 'redux-saga/effects';
import axios from "axios";
import { API_URL } from "../../utils/constants";
import setAuthToken from "../../utils/setAuthToken";
import { EncryptFunction, EncryptObjectFunction } from "../../utils/cryptoFunction";

const authSagas = function* () {
    yield all([
        yield takeEvery(authActions.AUTH_LOGIN, authLogin),
        yield takeEvery(authActions.AUTH_LOGOUT, authLogout),
    ])
};

const authLogin = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/login`, payload.data
            )
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate('/home');
            const encryptedtype = EncryptFunction(result?.data?.data?.typeOfUser);
            const encryptedrole = EncryptObjectFunction(result?.data?.data?.role);
            const encryptId = EncryptFunction(result?.data?.data?.id);
            sessionStorage.setItem('cet_token', result?.data?.token);
            sessionStorage.setItem('user_type', encryptedtype);
            sessionStorage.setItem('user_role', encryptedrole);
            sessionStorage.setItem('auth_user', encryptId);
            setAuthToken(result.data.token);
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        console.log(err);
    };
};

//eslint-disable-next-line
const authLogout = function* () {

    try {
        sessionStorage.removeItem('cet_token');
        sessionStorage.removeItem('user_type');
        sessionStorage.removeItem('user_role');
        sessionStorage.removeItem('auth_user');
        window.location.href = '/';
        // window.location.reload();
    } catch (err) {
        console.log(err);
    };
};

export default authSagas;