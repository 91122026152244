import React from "react";
import Header from "../layouts/header";
import UsersIndex from "../components/users";

export default function Users() {

    return (
        <div>
            <Header />
            <UsersIndex />
        </div>
    )
};