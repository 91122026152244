import React from "react";
import Header from "../layouts/header";
import LoginIndex from "../components/login";

export default function Login() {

    return (
        <div>
            <Header />
            <LoginIndex />
        </div>
    )
};