import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiPencilSimpleBold } from "react-icons/pi";

export default function AddRoleIndex() {

    const { register, handleSubmit, formState: { errors } } = useForm();

    let [permission, setPermission] = useState({ view: false, edit: false });

    const onSubmit = (data) => {

    };

    const handlePermissionChange = (e, type) => {
        let permissionObj = { ...permission };
        permissionObj[type] = !permissionObj[type];
        console.log(permissionObj)
        setPermission(permissionObj);
    };

    return (
        <Container className="component-container">
            <div className="list-heading-div">
                <div className="list-title">ROLE</div>
                <div className="list-title-line" />
            </div>
            <div className="form-container-div">
                <div className="form-container-header"><PiPencilSimpleBold /> Add Data</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.roleName?.message}</small>
                            <input
                                className="form-control input-field"
                                {...register('roleName', {
                                    required: "Role name is required"
                                })}
                            />
                            <label className="input-form-label">Role Name</label>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.description?.message}</small>
                            <textarea
                                className="form-control input-field"
                                {...register('description', {
                                    required: "Description is required"
                                })}
                            />
                            <label className="input-form-label">Description</label>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div className="login-form-group form-group">
                            <div className="form-input-radio-group">
                                {/* <small className="text-danger">{errors?.candidateName?.message}</small> */}
                                <div className="d-flex">
                                    <input
                                        type="radio"
                                        className="input-field-radio"
                                        name="view"
                                        onChange={(e) => handlePermissionChange(e, 'view')}
                                        // defaultChecked={permission?.view}
                                    />
                                    <label className="ps-2">View</label>
                                </div>
                                <div className="d-flex">
                                    <input
                                        type="radio"
                                        className="input-field-radio"
                                        name="edit"
                                        onChange={(e) => handlePermissionChange(e, 'edit')}
                                        // defaultChecked={permission?.edit}
                                    />
                                    <label className="ps-2">Edit</label>
                                </div>
                            </div>
                            <label className="input-form-label">Permission</label>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="login-btn-div">
                            <Button
                                className="login-signin-btn"
                                onClick={handleSubmit(onSubmit)}
                            >
                                SAVE
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};