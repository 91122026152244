import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../redux/common/actions";
import usersActions from "../redux/users/actions";

export default function DeleteConfirmationModal() {

    const dispatch = useDispatch();

    const { showDeleteModal, buttonLoader } = useSelector(state => state.commonReducer);

    const handleClose = () => {
        dispatch({ type: commonActions.SHOW_DELETE_CONFIRMATION_MODAL, payload: { show: false, data: null } });
    };

    const handleDeleteUser = () => {
        dispatch({ type: usersActions.DELETE_USER, payload: { id: showDeleteModal?.data } });
    };

    return (
        <Modal show={showDeleteModal?.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete the user?</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleDeleteUser}>
                    {buttonLoader ? <Spinner variant="light" size="sm" /> : "Yes"}
                </Button>
                <Button variant="secondary" onClick={handleClose} disabled={buttonLoader ? true : false}>
                    No
                </Button>
            </Modal.Footer>

        </Modal>
    )
}