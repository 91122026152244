import React, { useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiPencilSimpleBold } from "react-icons/pi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AlphabetsValidation, MobileNumberFirstNumberValidation, MobileNumberValidation, handleEmailValidation, trimFunction } from "../../utils/validation";
import { DecryptFunction } from "../../utils/cryptoFunction";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import candidateActions from "../../redux/candidates/actions";
import commonActions from "../../redux/common/actions";
import { MdOutlineArrowBack } from "react-icons/md";

export default function EditCandidateIndex() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const { state } = useLocation();

    const { buttonLoader } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    useEffect(() => {
        let details = { ...state };
        reset({ ...details });
        //eslint-disable-next-line
    }, [state]);

    const onSubmit = (data) => {
        if (state?.candidateName !== data?.candidateName || state?.emailId !== data?.emailId ||
            state?.mobileNo !== data?.mobileNo || state?.dob !== data?.dob) {
            const decryptedId = DecryptFunction(id);
            let updating_userid = DecryptFunction(sessionStorage.getItem('auth_user'));
            if (decryptedId) {
                const formData = {
                    candidateName: trimFunction(data?.candidateName?.toUpperCase()), emailId: trimFunction(data?.emailId?.toLowerCase()),
                    dob: data?.dob, mobileNo: data?.mobileNo, updating_userid: parseInt(updating_userid)
                };
                dispatch({
                    type: candidateActions.UPDATE_CANDIDATE,
                    payload: {
                        id: decryptedId,
                        data: formData,
                        navigate: navigate
                    }
                });
            }
        } else {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: {
                    status: 'failed',
                    show: true,
                    message: 'No fields have been updated.'
                }
            });
            setTimeout(() => {
                dispatch({
                    type: commonActions.SET_ALERT,
                    payload: { status: null, show: false, message: null }
                });
            }, 2000);
        }
    };

    return (
        <Container className="component-container">
            <div className="home-menu-main-div">
                <div className="list-heading-div">
                    <div className="list-title">CANDIDATES</div>
                    <div className="list-title-line" />
                </div>
                <div className="back-btn-div-cls">
                    <Button className="back-btn-cls" onClick={() => navigate('/home')}><MdOutlineArrowBack /> BACK</Button>
                </div>
            </div>
            <div className="form-container-div">
                <div className="form-container-header"><PiPencilSimpleBold /> Edit Data</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.candidateName?.message}</small>
                            <input
                                type="text"
                                className="form-control input-field text-uppercase"
                                {...register('candidateName', {
                                    required: "Name is required"
                                })}
                                onInput={AlphabetsValidation}
                            />
                            <label className="input-form-label">Name</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.emailId?.message}</small>
                            <input
                                className="form-control input-field text-lowercase"
                                {...register('emailId', {
                                    required: "Email id is required",
                                    validate: handleEmailValidation
                                })}
                            />
                            <label className="input-form-label">E-mail ID</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.mobileNo?.message}</small>
                            <input
                                type="tel"
                                maxLength={10}
                                className="form-control input-field"
                                {...register('mobileNo', {
                                    required: "Mobile number is required",
                                    minLength: {
                                        value: 10,
                                        message: "Enter valid mobile number",
                                    },
                                    validate: MobileNumberFirstNumberValidation
                                })}
                                onInput={MobileNumberValidation}
                            />
                            <label className="input-form-label">Mobile No</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.dob?.message}</small>
                            <input
                                type="date"
                                className="form-control input-field"
                                {...register('dob', {
                                    required: "Date of birth is required"
                                })}
                                max={moment().format("YYYY-MM-DD")}
                            />
                            <label className="input-form-label">DOB (DD-MM-YYYY)</label>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="login-btn-div">
                            <Button
                                type="submit"
                                className="login-signin-btn"
                                onClick={handleSubmit(onSubmit)}
                            >
                                {buttonLoader ? <Spinner variant="light" size="sm" /> : "SAVE"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};