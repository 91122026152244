import React from "react";
import Header from "../layouts/header";
import EditCandidateIndex from "../components/editCandidate";

export default function EditCandidate() {

    return (
        <div>
            <Header />
            <EditCandidateIndex />
        </div>
    )
};