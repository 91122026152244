import React from "react";
import { Navigate } from "react-router-dom";

export default function PublicRoute(props) {

    return (
        <>
            {
                    (sessionStorage.getItem('cet_token')) ? (
                        <Navigate to={'/home'} />
                    )
                        :
                        (
                            props.children
                        )
            }
        </>
    )
};