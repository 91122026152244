import React from "react";
import Header from "../layouts/header";
import ViewCandidateIndex from "../components/viewCandidate";

export default function ViewCandidate() {

    return (
        <div>
            <Header />
            <ViewCandidateIndex />
        </div>
    )
};