import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Menu from "./menu";
import List from "./list";
import { useDispatch } from "react-redux";
import candidateActions from "../../redux/candidates/actions";
import commonActions from "../../redux/common/actions";

export default function CandidatesIndex() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: candidateActions.GET_CANDIDATE_LIST });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        return (() => {
            dispatch({ type: commonActions.SET_PAGINATION_CURRENT_PAGE, payload: 0 });
        });
        //eslint-disable-next-line
    }, []);

    return (
        <Container className="component-container">
            <Menu />
            <List />
        </Container>
    )
};