import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import candidateActions from "../../redux/candidates/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { Button, Col, Container, Row } from "react-bootstrap";
import moment from "moment-timezone";
import { MdOutlineArrowBack } from "react-icons/md";

export default function ViewCandidateIndex() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    const { candidateDetails } = useSelector(state => state.candidateReducer);

    useEffect(() => {
        const userid = DecryptFunction(id);
        console.log(userid)
        if (userid) {
            dispatch({ type: candidateActions.GET_CANDIDATE_DETAILS, payload: userid });
        }
        //eslint-disable-next-line
    }, [id]);

    return (
        <Container className="component-container">
            <div className="home-menu-main-div">
                <div className="list-heading-div">
                    <div className="list-title">CANDIDATE INFO</div>
                    <div className="list-title-line" />
                </div>
                <div className="back-btn-div-cls">
                    <Button className="back-btn-cls" onClick={() => navigate('/home')}><MdOutlineArrowBack /> BACK</Button>
                </div>
            </div>
            <div className="form-container-div">
                <div className="view-form-section-heading pb-2">Personal Info</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.candidateName}
                            />
                            <label className="input-view-form-label">Name</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.emailId}
                            />
                            <label className="input-view-form-label">E-mail ID</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.mobileNo}
                            />
                            <label className="input-view-form-label">Mobile No</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.dob ? moment(candidateDetails?.dob, 'YYYY-MM-DD').format('DD-MM-YYYY') : ''}
                            />
                            <label className="input-view-form-label">DOB</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.gender}
                            />
                            <label className="input-view-form-label">Gender</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.fatherName}
                            />
                            <label className="input-view-form-label">Father's Name</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.motherName}
                            />
                            <label className="input-view-form-label">Mother's Name</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.religion}
                            />
                            <label className="input-view-form-label">Religion</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.region}
                            />
                            <label className="input-view-form-label">Region</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.motherTongue}
                            />
                            <label className="input-view-form-label">Mother Tongue</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.nationalityname}
                            />
                            <label className="input-view-form-label">Nationality</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.annualFamilyIncome}
                            />
                            <label className="input-view-form-label">Annual Family Income</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.maritalStatus}
                            />
                            <label className="input-view-form-label">Marital Status</label>
                        </div>
                    </Col>
                </Row>
                <div className="view-form-section-heading pt-4 pb-2">Permanent Address</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pAddressLine1}
                            />
                            <label className="input-view-form-label">Address Line1</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pAddressLine2}
                            />
                            <label className="input-view-form-label">Address Line2</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pAddressLine3}
                            />
                            <label className="input-view-form-label">Address Line3</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pstatename}
                                // value={candidateDetails?.pState}
                            />
                            <label className="input-view-form-label">State</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pDistrictName}
                                // value={candidateDetails?.pDistrict}
                            />
                            <label className="input-view-form-label">District</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pTalukaname}
                                // value={candidateDetails?.pTaluka}
                            />
                            <label className="input-view-form-label">Taluka</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pVillagename}
                                // value={candidateDetails?.pVillage}
                            />
                            <label className="input-view-form-label">Village</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.pPincode}
                            />
                            <label className="input-view-form-label">Pincode</label>
                        </div>
                    </Col>
                </Row>
                <div className="view-form-section-heading pt-4 pb-2">Address for Correspondence</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cAddressLine1}
                            />
                            <label className="input-view-form-label">Address Line1</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cAddressLine2}
                            />
                            <label className="input-view-form-label">Address Line2</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cAddressLine3}
                            />
                            <label className="input-view-form-label">Address Line3</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cStatename}
                                // value={candidateDetails?.cState}
                            />
                            <label className="input-view-form-label">State</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cDistrictName}
                                // value={candidateDetails?.cDistrict}
                            />
                            <label className="input-view-form-label">District</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cTalukaName}
                                // value={candidateDetails?.cTaluka}
                            />
                            <label className="input-view-form-label">Taluka</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cVillageName}
                                // value={candidateDetails?.cVillage}
                            />
                            <label className="input-view-form-label">Village</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.cPincode}
                            />
                            <label className="input-view-form-label">Pincode</label>
                        </div>
                    </Col>
                </Row>
                <div className="view-form-section-heading pt-4 pb-2">Other Details</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.isMhCandidate}
                            />
                            <label className="input-view-form-label">Are you domiciled in Maharashtra?</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.appliedForDisabilityStatus}
                            />
                            <label className="input-view-form-label">Are you person with disability?</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.sscPassingYear}
                            />
                            <label className="input-view-form-label">SSC/Equivalent Passing Year</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.sscTotalPercentage}
                            />
                            <label className="input-view-form-label">SSC/Equivalent Total Percentage</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.sscBoardname}
                                // value={candidateDetails?.sscBoard}
                            />
                            <label className="input-view-form-label">SSC/Equivalent Board</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.sscSchoolStatename}
                                // value={candidateDetails?.sscSchoolState}
                            />
                            <label className="input-view-form-label">SSC School State</label>
                        </div>
                    </Col>
                </Row>
                <div className="view-form-section-heading pt-4 pb-2">HSC Details</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.hscPassingStatus}
                            />
                            <label className="input-view-form-label">HSC/Equivalent Passing Status</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.hscPassingYear}
                            />
                            <label className="input-view-form-label">HSC/Equivalent Passing Year</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.hscBoardname}
                                // value={candidateDetails?.hscBoard}
                            />
                            <label className="input-view-form-label">HSC/Equivalent Board</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.hscMarksType}
                            />
                            <label className="input-view-form-label">HSC Mark Type</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.hscMarksObtained}
                            />
                            <label className="input-view-form-label">Marks obtained in HSC</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.hscMarksOutOf}
                            />
                            <label className="input-view-form-label">Out of</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group fw-400">
                            <input
                                className="form-control input-field input-field-disabled"
                                disabled
                                value={candidateDetails?.hscPercentage}
                            />
                            <label className="input-view-form-label">HSC/Equivalent Total Percentage</label>
                        </div>
                    </Col>
                </Row>

            </div>
        </Container>
    )
};