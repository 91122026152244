import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./routes/privateRoute";
import PublicRoute from "./routes/publicRoute";

import Login from "./pages/login";
import Candidates from "./pages/candidates";
import Users from "./pages/users";
import AddUser from "./pages/addUser";
import EditUser from "./pages/editUser";
import Role from "./pages/role";
import AddRole from "./pages/addRole";
import EditCandidate from "./pages/editCandidate";
import ToastMessage from "./common/toastMessage";
import setAuthToken from "./utils/setAuthToken";
import ViewCandidate from "./pages/viewCandidate";
import LogoutModal from "./common/logoutModal";

if (sessionStorage.getItem('cet_token')) {
  setAuthToken(sessionStorage.getItem('cet_token'));
};

function App() {
  return (
    <>
      <Router>
        <ToastMessage />
        <LogoutModal />
        <Routes>
          <Route path="/" element={<PublicRoute><Login /></PublicRoute>} />

          <Route path="/home" element={<PrivateRoute><Candidates /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
          <Route path="/add-user" element={<PrivateRoute><AddUser /></PrivateRoute>} />
          <Route path="/edit-user" element={<PrivateRoute><EditUser /></PrivateRoute>} />
          <Route path="/role" element={<PrivateRoute><Role /></PrivateRoute>} />
          <Route path="/add-role" element={<PrivateRoute><AddRole /></PrivateRoute>} />
          <Route path="/edit-candidate" element={<PrivateRoute><EditCandidate /></PrivateRoute>} />
          <Route path="/view-candidate" element={<PrivateRoute><ViewCandidate /></PrivateRoute>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
