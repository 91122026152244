import commonActions from "./actions";

const initialState = {
    alert: {
        show: false,
        message: null,
        status: null
    },
    buttonLoader: false,
    paginationCurrentPage: 0,
    searchText: null,
    showDeleteModal: {
        show: false,
        data: null
    },
    showLogoutModal: false,
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case commonActions.SET_ALERT:
            return {
                ...state,
                alert: action.payload
            }
        case commonActions.SET_BUTTON_LOADER:
            return {
                ...state,
                buttonLoader: action.payload
            }
        case commonActions.SET_PAGINATION_CURRENT_PAGE:
            return {
                ...state,
                paginationCurrentPage: action.payload
            }
        case commonActions.SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload
            }
        case commonActions.SHOW_DELETE_CONFIRMATION_MODAL:
            return {
                ...state,
                showDeleteModal: action.payload
            }
        case commonActions.SET_LOGOUT_MODAL:
            return {
                ...state,
                showLogoutModal: action.payload
            }
        default:
            return state;
    }
};

export default commonReducer;