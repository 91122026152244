import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import List from "./list";
import usersActions from "../../redux/users/actions";
import { useDispatch } from "react-redux";

export default function UsersIndex() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: usersActions.GET_USER_LIST });
        //eslint-disable-next-line
    }, []);

    return (
        <Container className="component-container">
            <List />
        </Container>
    )
};