import { put, call, all, takeEvery, delay } from "redux-saga/effects";
import candidateActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";

const candidateSaga = function* () {
    yield all([
        yield takeEvery(candidateActions.GET_CANDIDATE_DETAILS, getCandidateDetails),
        yield takeEvery(candidateActions.GET_SEARCH_CANDIDATE, candidateFilter),
        yield takeEvery(candidateActions.UPDATE_CANDIDATE_VIEW_LOG, updateCandidateViewLog),
        yield takeEvery(candidateActions.UPDATE_CANDIDATE, updateCandidate),
        yield takeEvery(candidateActions.UPDATE_CANDIDATE_EDIT_LOG, updateCandidateEditLog),
    ])
};

const getCandidateDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/candidate/details/${payload}`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: candidateActions.SET_CANDIDATE_DETAILS, payload: result?.data?.result });
        };
    } catch (err) {
        console.log(err);
    };
};

const candidateFilter = function* (data) {
    const { payload } = data;

    const result = yield call(() =>
        axios.get(`${API_URL}/candidate/${payload}`)
    );
    if (result?.data?.statusCode === 200) {
        yield put({ type: candidateActions.SET_FILTERED_CANDIDATE_LIST, payload: result?.data?.result });
    }
    else {
        yield put({ type: candidateActions.SET_FILTERED_CANDIDATE_LIST, payload: [] });
    }
};

const updateCandidateViewLog = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.post(`${API_URL}/candidate/view`, payload)
        );
    } catch (err) {
        console.log(err);
    };
};

const updateCandidate = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/candidate/${payload?.id}`, payload?.data)
        );
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate('/home');
            yield put({ type: candidateActions.GET_CANDIDATE_LIST });
            yield put({ type: candidateActions.SET_FILTERED_CANDIDATE_LIST, payload: [] });
            yield put({ type: commonActions.SET_SEARCH_TEXT, payload: null });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    };
};

const updateCandidateEditLog = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.post(`${API_URL}/candidate/edit`, payload)
        );
    } catch (err) {
        console.log(err);
    };
};

export default candidateSaga;