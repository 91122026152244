import { all } from "redux-saga/effects";

import authSagas from "./auth/sagas";
import usersSaga from "./users/sagas";
import roleSaga from "./role/sagas";
import candidateSaga from "./candidates/sagas";

export default function* rootSaga() {
    yield all([
        authSagas(),
        usersSaga(),
        roleSaga(),
        candidateSaga(),
    ])
};