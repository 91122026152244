import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../redux/common/actions";
import authActions from "../redux/auth/actions";

export default function LogoutModal() {

    const dispatch = useDispatch();

    const { showLogoutModal } = useSelector(state => state.commonReducer);

    const handleClose = () => {
        dispatch({ type: commonActions.SET_LOGOUT_MODAL, payload: false });
    };

    const handleLogout = () => {
        dispatch({ type: authActions.AUTH_LOGOUT });
    };

    return (
        <Modal show={showLogoutModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to logout?</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleLogout}>
                    Yes
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
            </Modal.Footer>

        </Modal>
    )
}