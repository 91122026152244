import React from "react";
import { Container } from "react-bootstrap";

export default function Header() {

    return (
        <Container fluid className="header-container-fluid">
            <Container className="header-container header-container-bigscreen">
                <img src={require('../assets/images/logo.png')} alt="logo" className="header-logo" />
                <div className="header-center-content">
                    <img src={require('../assets/images/cet_img.png')} alt="cet" className="header-center-img" />
                    <div className="header-heading-div">
                        <div className="header-heading1">State Common Entrance Test Cell</div>
                        {/* <div className="header-heading1">GOVERNMENT OF MAHARASHTRA</div>
                        <div className="header-heading2">State Common Entrance Test Cell</div> */}
                    </div>
                </div>
                <img src={require('../assets/images/header3_img.png')} alt="header" className="header-right-corner-img" />
            </Container>
            <div className="header-container header-container-smallscreen">
                <img src={require('../assets/images/logo.png')} alt="logo" className="header-logo" />
                <div className="header-center-content">
                    <img src={require('../assets/images/cet_img.png')} alt="cet" className="header-center-img" />
                    <div className="header-heading-div">
                        <div className="header-heading1">State Common Entrance Test Cell</div>
                        {/* <div className="header-heading1">GOVERNMENT OF MAHARASHTRA</div>
                        <div className="header-heading2">State Common Entrance Test Cell</div> */}
                    </div>
                </div>
                <img src={require('../assets/images/header3_img.png')} alt="header" className="header-right-corner-img" />
            </div>
        </Container>
    )
};