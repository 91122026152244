import React from "react";
import Header from "../layouts/header";
import EditUserIndex from "../components/editUser";

export default function EditUser() {

    return (
        <div>
            <Header />
            <EditUserIndex />
        </div>
    )
};