import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import candidateActions from "../../redux/candidates/actions";
import commonActions from "../../redux/common/actions";
import { FiLogOut } from "react-icons/fi";
import { InputTrimFunction } from "../../utils/validation";

export default function Menu() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { searchText } = useSelector(state => state.commonReducer);

    const [searchedText, setSearchedText] = useState('');

    useEffect(() => {
        if (searchText) {
            setSearchedText(searchText);
        };
    }, [searchText]);


    const handleSearchClick = () => {
        dispatch({ type: commonActions.SET_SEARCH_TEXT, payload: searchedText });
        dispatch({ type: candidateActions.GET_SEARCH_CANDIDATE, payload: searchedText })

    };

    const handleLogout = () => {
        dispatch({ type: commonActions.SET_LOGOUT_MODAL, payload: true });
        // dispatch({ type: authActions.AUTH_LOGOUT });
        // navigate('/');
    };

    return (
        <div className="home-menu-main-div">
            <div className="home-page-search-input-with-btn">
                <div className="home-search-div">
                    <input
                        className="form-control home-search"
                        placeholder="Enter Mobile No or Email Id"
                        defaultValue={searchedText}
                        onChange={(e) => setSearchedText(e.target.value)}
                        onInput={InputTrimFunction}
                    />
                    <IoSearchOutline className="home-search-icon" />
                </div>
                <Button className="menu-btn-cls menu-search-btn-cls" disabled={!searchedText ? true : false} onClick={handleSearchClick}>SEARCH</Button>
            </div>
            <div className="home-menu-btn">
                {DecryptFunction(sessionStorage.getItem('user_type')) === 'Master' &&
                    <>
                        <Button className="menu-btn-cls" onClick={() => navigate('/users')}>USER</Button>
                        {/* <Button className="menu-btn-cls" onClick={() => navigate('/role')}>ROLE</Button> */}
                    </>
                }
                <Button className="menu-logout-btn-cls" onClick={handleLogout}><FiLogOut className="me-1" /> LOGOUT</Button>
            </div>
        </div>
    )
}