import usersActions from "./actions";

const initialState = {
    users: [],
};

const usersReducer = (state = initialState, action) => {

    switch (action.type) {
        case usersActions.SET_USER_LIST:
            return {
                ...state,
                users: action.payload
            }
        default:
            return state;
    }
};

export default usersReducer;