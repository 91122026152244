import React from "react";
import { Navigate } from "react-router-dom";

export default function PrivateRoute(props) {

    return (
        <>
            {(sessionStorage.getItem('cet_token')) ?
                props.children
                :
                <Navigate to={"/"} />
            }
        </>
    )
};