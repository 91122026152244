import React, { useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { handleEmailValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";

export default function LoginIndex() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [passState, setPassState] = useState(false);

    const { buttonLoader } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        dispatch({ type: authActions.AUTH_LOGIN, payload: { data: data, navigate: navigate } });
    };

    return (
        <Container className="component-container">
            <div className="login-page-main-div">
                <div>
                    <h6 className="login-header">Admin - Sign in</h6>
                    <div className="login-form-group form-group">
                        <small className="text-danger">{errors?.emailId?.message}</small>
                        <input
                            className="form-control input-field"
                            {...register('emailId', {
                                required: "Email id is required",
                                validate: handleEmailValidation
                            })}
                        />
                        <label className="input-form-label">E-mail id</label>
                    </div>
                    <div className="login-form-group form-group">
                        <small className="text-danger">{errors?.password?.message}</small>
                        <div className="form-password-input">
                            <input
                                type={passState ? "text" : "password"}
                                className="form-control input-field"
                                {...register('password', {
                                    required: "Password is required"
                                })}
                            />
                            <a
                                href="#password"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setPassState(!passState);
                                }}
                                className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                            >
                                {!passState ?
                                    <FiEye className="password-eye-icon" /> :
                                    <FiEyeOff className="password-eye-icon" />
                                }
                            </a>
                        </div>
                        <label className="input-form-label">Password</label>
                    </div>
                    <div className="login-btn-div">
                        <Button
                            type="submit"
                            className="login-signin-btn"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {buttonLoader ? <Spinner variant="light" size="sm" /> : "Sign in"}
                        </Button>
                    </div>
                </div>
            </div>
        </Container>
    )
};