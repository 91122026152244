import commonReducer from "./common/reducer";
import usersReducer from "./users/reducer";
import roleReducer from "./role/reducer";
import candidateReducer from "./candidates/reducer";

const Reducers = {
    commonReducer,
    usersReducer,
    roleReducer,
    candidateReducer,
};

export default Reducers;