import React from "react";
import { Button, Table } from "react-bootstrap";
import { PiPencilSimpleBold, PiTrashBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";
import { EncryptFunction } from "../../utils/cryptoFunction";
import commonActions from "../../redux/common/actions";
import DeleteConfirmationModal from "../../common/deleteConfirmationModal";

export default function List() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { users } = useSelector(state => state.usersReducer);

    const handleDeleteUser = (id) => {
        dispatch({ type: commonActions.SHOW_DELETE_CONFIRMATION_MODAL, payload: { show: true, data: id } });
    };

    return (
        <div className="list-component-main-div">
            <div className="home-menu-main-div component-heading-main-div">
                <div className="list-heading-div">
                    <div className="list-title">USERS</div>
                    <div className="list-title-line" />
                </div>
                <div className="home-menu-btn align-items-center">
                    <div className="home-menu-btn add-btn-div-cls">
                        <Button className="menu-btn-cls" onClick={() => navigate('/add-user')}>ADD USER</Button>
                    </div>
                    <div className="back-btn-div-cls">
                        <Button className="back-btn-cls" onClick={() => navigate('/home')}><MdOutlineArrowBack /> BACK</Button>
                    </div>
                </div>
            </div>
            <Table responsive className="list-table-cls">
                <thead className="list-thead-cls">
                    <tr className="list-thead-tr">
                        <th>Name</th>
                        <th>E-mail</th>
                        <th>Mobile No</th>
                        <th>DOB</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((val, i) =>
                        <tr key={i} className="list-tbody-tr-cls">
                            <td>{val?.name}</td>
                            <td>{val?.emailId}</td>
                            <td>{val?.mobileNo}</td>
                            <td>{val?.dob}</td>
                            <td className="list-table-edit" onClick={() => navigate(`/edit-user?id=${EncryptFunction(val?.id)}`, { state: val })}>
                                <PiPencilSimpleBold /> <span>Edit</span>
                            </td>
                            <td className="list-table-delete" onClick={() => handleDeleteUser(val?.id)}>
                                <PiTrashBold /> <span>Delete</span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <DeleteConfirmationModal />
        </div>
    )
}