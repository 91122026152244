import candidateActions from "./actions";

const initialState = {
    candidatesList: [],
    filteredCandidatesList: [],
    candidateDetails: null,
};

const candidateReducer = (state = initialState, action) => {
    switch (action.type) {
        case candidateActions.SET_CANDIDATE_LIST:
            return {
                ...state,
                candidatesList: action.payload
            }
        case candidateActions.SET_FILTERED_CANDIDATE_LIST:
            return {
                ...state,
                filteredCandidatesList: action.payload
            }
        case candidateActions.SET_CANDIDATE_DETAILS:
            return {
                ...state,
                candidateDetails: action.payload
            }
            case candidateActions.SET_SEARCH_CANDIDATE:
                return {
                    ...state,
                    searchCandidate: action.payload
                }
        default:
            return state;
    }
};

export default candidateReducer;