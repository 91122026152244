import React, { useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiPencilSimpleBold } from "react-icons/pi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AlphabetsValidation, InputTrimFunction, MobileNumberFirstNumberValidation, MobileNumberValidation, handleEmailValidation, trimFunction } from "../../utils/validation";
import roleActions from "../../redux/role/actions";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineArrowBack } from "react-icons/md";
// import { FiEye, FiEyeOff } from "react-icons/fi";
import moment from "moment-timezone";
import { DecryptFunction } from "../../utils/cryptoFunction";
import usersActions from "../../redux/users/actions";
import commonActions from "../../redux/common/actions";

export default function EditUserIndex() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const { state } = useLocation();

    const { role } = useSelector(state => state.roleReducer);

    const { buttonLoader } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    // const [passState, setPassState] = useState(false);

    useEffect(() => {
        reset({
            name: state?.name, emailId: state?.emailId, mobileNo: state?.mobileNo,
            dob: state?.dob, roleId: state?.roleId
            // password: state?.password, 
        });
        //eslint-disable-next-line
    }, [state]);

    useEffect(() => {
        dispatch({ type: roleActions.GET_ROLE_LIST });
        //eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        if (state?.name !== data?.name || state?.emailId !== data?.emailId || state?.mobileNo !== data?.mobileNo ||
            state?.roleId !== data?.roleId) {
            const decryptedId = DecryptFunction(id);
            if (decryptedId) {
                const formData = {
                    ...data,
                    name: trimFunction(data?.name),
                    emailId: trimFunction(data?.emailId)
                }
                dispatch({
                    type: usersActions.UPDATE_USER,
                    payload: { id: decryptedId, data: formData, navigate: navigate }
                });
            };
        } else {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: {
                    status: 'failed',
                    show: true,
                    message: 'No fields have been updated.'
                }
            });
            setTimeout(() => {
                dispatch({
                    type: commonActions.SET_ALERT,
                    payload: { status: null, show: false, message: null }
                });
            }, 2000);
        }
    };

    return (
        <Container className="component-container">
            <div className="home-menu-main-div">
                <div className="list-heading-div">
                    <div className="list-title">USERS</div>
                    <div className="list-title-line" />
                </div>
                <div className="back-btn-div-cls">
                    <Button className="back-btn-cls" onClick={() => navigate('/users')}><MdOutlineArrowBack /> BACK</Button>
                </div>
            </div>
            <div className="form-container-div">
                <div className="form-container-header"><PiPencilSimpleBold /> Edit Data</div>
                <Row>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.name?.message}</small>
                            <input
                                className="form-control input-field"
                                {...register('name', {
                                    required: "Name is required"
                                })}
                                onInput={AlphabetsValidation}
                            />
                            <label className="input-form-label">Name</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.emailId?.message}</small>
                            <input
                                className="form-control input-field"
                                {...register('emailId', {
                                    required: "Email id is required",
                                    validate: handleEmailValidation
                                })}
                                onInput={InputTrimFunction}
                            />
                            <label className="input-form-label">E-mail ID</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.mobileNo?.message}</small>
                            <input
                                type="tel"
                                maxLength={10}
                                className="form-control input-field"
                                {...register('mobileNo', {
                                    required: "Mobile number is required",
                                    minLength: {
                                        value: 10,
                                        message: "Enter valid mobile number",
                                    },
                                    validate: MobileNumberFirstNumberValidation
                                })}
                                onInput={MobileNumberValidation}
                            />
                            <label className="input-form-label">Mobile No</label>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.dob?.message}</small>
                            <input
                                type="date"
                                max={moment().format('YYYY-MM-DD')}
                                className="form-control input-field"
                                {...register('dob', {
                                    required: "Date of birth is required"
                                })}
                            />
                            <label className="input-form-label">DOB</label>
                        </div>
                    </Col>
                    {/* <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.password?.message}</small>
                            <div className="form-password-input">
                                <input
                                    type={passState ? "text" : "password"}
                                    className="form-control input-field"
                                    {...register('password', {
                                        required: "Password is required"
                                    })}
                                />
                                <a
                                    href="#password"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        setPassState(!passState);
                                    }}
                                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                                >
                                    {!passState ?
                                        <FiEye className="password-eye-icon" /> :
                                        <FiEyeOff className="password-eye-icon" />
                                    }
                                </a>
                            </div>
                            <label className="input-form-label">Password</label>
                        </div>
                    </Col> */}
                    <Col md={6}>
                        <div className="login-form-group form-group">
                            <small className="text-danger">{errors?.roleId?.message}</small>
                            <select
                                className="form-control input-field form-control-select"
                                {...register('roleId', {
                                    required: "Role is required",
                                    valueAsNumber: true
                                })}
                                defaultValue={''}
                            >
                                <option value={''} disabled>--Select--</option>
                                {role?.map((val, i) =>
                                    <option value={val?.id} key={i}>{val?.roleName}</option>
                                )}
                            </select>
                            <label className="input-form-label">Role</label>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="login-btn-div">
                            <Button
                                className="login-signin-btn"
                                onClick={handleSubmit(onSubmit)}
                            >
                                {buttonLoader ? <Spinner variant="light" size="sm" /> : "SAVE"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};