import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import List from "./list";
import { useDispatch } from "react-redux";
import roleActions from "../../redux/role/actions";

export default function RoleIndex() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: roleActions.GET_ROLE_LIST });
        //eslint-disable-next-line
    }, []);

    return (
        <Container className="component-container">
            <List />
        </Container>
    )
};