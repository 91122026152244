import { put, call, all, takeEvery } from "redux-saga/effects";
import roleActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const roleSaga = function* () {
    yield all([
        yield takeEvery(roleActions.GET_ROLE_LIST, getRoleList),
    ])
};

const getRoleList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/role`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: roleActions.SET_ROLE_LIST, payload: result?.data?.result });
        }
    } catch (err) {
        console.log(err);
    };
};

export default roleSaga;