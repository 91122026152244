import React from "react";
import Header from "../layouts/header";
import RoleIndex from "../components/role";

export default function Role() {

    return (
        <div>
            <Header />
            <RoleIndex />
        </div>
    )
};