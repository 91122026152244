const candidateActions = {
    GET_CANDIDATE_LIST: 'GET_CANDIDATE_LIST',
    SET_CANDIDATE_LIST: 'SET_CANDIDATE_LIST',
    GET_CANDIDATE_DETAILS: 'GET_CANDIDATE_DETAILS',
    SET_CANDIDATE_DETAILS: 'SET_CANDIDATE_DETAILS',
    CANDIDATE_FILTER: 'CANDIDATE_FILTER',
    SET_FILTERED_CANDIDATE_LIST: 'SET_FILTERED_CANDIDATE_LIST',
    UPDATE_CANDIDATE_VIEW_LOG: 'UPDATE_CANDIDATE_VIEW_LOG',
    UPDATE_CANDIDATE_EDIT_LOG: 'UPDATE_CANDIDATE_EDIT_LOG',
    UPDATE_CANDIDATE :'UPDATE_CANDIDATE',
    GET_SEARCH_CANDIDATE :'GET_SEARCH_CANDIDATE',
    SET_SEARCH_CANDIDATE :'SET_SERACH_CANDIDATE'


};

export default candidateActions;